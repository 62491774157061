import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: 'https://api.leagueofroosters.com/api', // Replace with your base URL
    timeout: 10000, // Optional: Set a timeout for requests
    headers: {
        'Content-Type': 'application/json',
        // Add any custom headers here
    },
});

// Optional: Set up request/response interceptors
axiosInstance.interceptors.request.use(
    config => {
        // Modify request before sending it (e.g., add authorization headers)
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        // Handle the response data here
        return response;
    },
    error => {
        // Handle errors here
        return Promise.reject(error);
    }
);

export default axiosInstance;
