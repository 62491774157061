import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Register the necessary components and plugins with Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

function ChartComponent() {
    const [matchStats, setMatchStats] = useState([]);
    const [chartData, setChartData] = useState({});
    const [isDrillDown, setIsDrillDown] = useState(false);
    const [currentSeason, setCurrentSeason] = useState(null);
    const [currentSummoner, setCurrentSummoner] = useState(null);
    const [filteredTableData, setFilteredTableData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'GamesPlayed', direction: 'descending' });

    useEffect(() => {
        // Fetch data from the API endpoint
        axios.get('https://api.leagueofroosters.com/api/match_stats')
            .then(response => {
                const sortedData = response.data.sort((a, b) => b.GamesPlayed - a.GamesPlayed);
                setMatchStats(sortedData);
                createSeasonChartData(sortedData);
                setFilteredTableData(sortedData);
            })
            .catch(error => {
                console.error("There was an error fetching the match stats!", error);
            });
    }, []);

    const createSeasonChartData = (data) => {
        const seasonGamesPlayed = {};

        data.forEach(stat => {
            if (seasonGamesPlayed[stat.season]) {
                seasonGamesPlayed[stat.season] += stat.GamesPlayed;
            } else {
                seasonGamesPlayed[stat.season] = stat.GamesPlayed;
            }
        });

        setChartData({
            labels: Object.keys(seasonGamesPlayed),
            datasets: [
                {
                    label: 'Games Played by Season',
                    data: Object.values(seasonGamesPlayed),
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                }
            ]
        });
    };

    const createSummonerChartData = (season) => {
        const filteredData = matchStats.filter(stat => stat.season === season);

        const summonerGamesPlayed = {};

        filteredData.forEach(stat => {
            if (summonerGamesPlayed[stat.summoner]) {
                summonerGamesPlayed[stat.summoner] += stat.GamesPlayed;
            } else {
                summonerGamesPlayed[stat.summoner] = stat.GamesPlayed;
            }
        });

        setChartData({
            labels: Object.keys(summonerGamesPlayed),
            datasets: [
                {
                    label: `Games Played by Summoners for Season ${season}`,
                    data: Object.values(summonerGamesPlayed),
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                }
            ]
        });
        setIsDrillDown(true);
        setCurrentSeason(season);
        setFilteredTableData(filteredData);
    };

    const createChampionChartData = (summoner) => {
        const championGamesPlayed = {
            win: {},
            loss: {}
        };

        matchStats.filter(stat => stat.summoner === summoner && stat.season === currentSeason).forEach(stat => {
            const category = stat.winloss === 'W' ? 'win' : 'loss';
            if (championGamesPlayed[category][stat.champion]) {
                championGamesPlayed[category][stat.champion] += stat.GamesPlayed;
            } else {
                championGamesPlayed[category][stat.champion] = stat.GamesPlayed;
            }
        });

        const champions = Object.keys({ ...championGamesPlayed.win, ...championGamesPlayed.loss });
        const winData = champions.map(champion => championGamesPlayed.win[champion] || 0);
        const lossData = champions.map(champion => championGamesPlayed.loss[champion] || 0);

        setChartData({
            labels: champions,
            datasets: [
                {
                    label: 'Wins',
                    data: winData,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    stack: 'Stack 0',
                    borderWidth: 1,
                },
                {
                    label: 'Losses',
                    data: lossData,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    stack: 'Stack 0',
                    borderWidth: 1,
                }
            ]
        });

        setCurrentSummoner(summoner);
        setFilteredTableData(matchStats.filter(stat => stat.summoner === summoner && stat.season === currentSeason));
    };

    const handleGoBack = () => {
        if (currentSummoner) {
            createSummonerChartData(currentSeason);
            setCurrentSummoner(null);
        } else {
            createSeasonChartData(matchStats);
            setIsDrillDown(false);
            setCurrentSeason(null);
            setFilteredTableData(matchStats);
        }
    };

    const onBarClick = (event, elements) => {
        if (!elements.length) return;

        const index = elements[0].index;
        if (!isDrillDown) {
            const selectedSeason = chartData.labels[index];
            createSummonerChartData(Number(selectedSeason));
        } else if (currentSeason && !currentSummoner) {
            const selectedSummoner = chartData.labels[index];
            createChampionChartData(selectedSummoner);
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...filteredTableData].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setFilteredTableData(sortedData);
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>League of Roosters Analysis</h1>
            {(isDrillDown || currentSummoner) && (
                <button onClick={handleGoBack} style={styles.button}>Back</button>
            )}
            <div style={styles.chartContainer}>
                <h2 style={styles.chartTitle}>Games Played</h2>
                {chartData.labels ? (
                    <Bar 
                        data={chartData} 
                        options={{
                            plugins: {
                                legend: { display: true },
                                datalabels: {
                                    display: true,
                                    color: '#fff',
                                    formatter: (value) => value,
                                    anchor: 'end',
                                    align: 'top'
                                }
                            },
                            onClick: onBarClick,
                            scales: {
                                x: {
                                    ticks: { color: '#d4d4d4' } 
                                },
                                y: {
                                    beginAtZero: true,
                                    ticks: { color: '#d4d4d4' } 
                                }
                            }
                        }} 
                    />
                ) : (
                    <p>Loading chart...</p>
                )}
            </div>
            <MatchStatsTable matchStats={filteredTableData} handleSort={handleSort} />
        </div>
    );
}

// Component to display match statistics in a styled, scrollable, sortable table
function MatchStatsTable({ matchStats, handleSort }) {
    return (
        <div style={styles.tableContainer}>
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort('summoner')}>Summoner</th>
                        <th onClick={() => handleSort('season')}>Season</th>
                        <th onClick={() => handleSort('champion')}>Champion</th>
                        <th onClick={() => handleSort('winloss')}>Win/Loss</th>
                        <th onClick={() => handleSort('GamesPlayed')}>Games Played</th>
                        <th onClick={() => handleSort('DoubleKill')}>Double Kill</th>
                        <th onClick={() => handleSort('TripleKill')}>Triple Kill</th>
                        <th onClick={() => handleSort('QuadraKill')}>Quadra Kill</th>
                        <th onClick={() => handleSort('PentaKill')}>Penta Kill</th>
                    </tr>
                </thead>
                <tbody>
                    {matchStats.map((stat, index) => (
                        <tr key={index}>
                            <td>{stat.summoner}</td>
                            <td>{stat.season}</td>
                            <td>{stat.champion}</td>
                            <td>{stat.winloss}</td>
                            <td>{stat.GamesPlayed}</td>
                            <td>{stat.DoubleKill}</td>
                            <td>{stat.TripleKill}</td>
                            <td>{stat.QuadraKill}</td>
                            <td>{stat.PentaKill}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

// Styles for the dark mode and layout
const styles = {
    container: {
        backgroundColor: '#1e1e1e',
        color: '#d4d4d4',
        minHeight: '100vh',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    title: {
        color: '#d4d4d4',
        textAlign: 'center',
        marginBottom: '20px'
    },
    chartTitle: {
        textAlign: 'center',
        color: '#d4d4d4',
        marginBottom: '10px'
    },
    chartContainer: {
        border: '1px solid #3c3c3c',
        borderRadius: '4px',
        padding: '20px',
        marginBottom: '20px',
        backgroundColor: '#2d2d2d'
    },
    button: {
        backgroundColor: '#007acc',
        color: '#fff',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginBottom: '20px'
    },
    tableContainer: {
        border: '1px solid #3c3c3c',
        borderRadius: '4px',
        overflowY: 'auto',
        maxHeight: '400px',
        backgroundColor: '#2d2d2d'
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        color: '#d4d4d4'
    },
    th: {
        border: '1px solid #3c3c3c',
        padding: '8px',
        textAlign: 'left',
        backgroundColor: '#333333',
        cursor: 'pointer'
    },
    td: {
        border: '1px solid #3c3c3c',
        padding: '8px'
    }
};

export default ChartComponent;
